import React, { useCallback, useEffect, useState } from 'react';
import { bool } from 'prop-types';

const DotsLoader = ({ isLoading }) => {
  const [dots, setDots] = useState('');
  const [current, setCurrent] = useState(1);
  const styles = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    lineHeight: 'normal',
  };

  const render = useCallback(
    () => setDots(new Array(current).fill('.').join('')),
    [current],
  );

  const start = () => {
    const interval = setInterval(
      () => setCurrent(state => (state === 4 ? 1 : state + 1)),
      400,
    );

    return () => clearInterval(interval);
  };

  useEffect(start, []);
  useEffect(render, [current]);

  return (
    <span style={{ position: 'relative' }}>
      {isLoading && <span style={styles}>{dots}</span>}
    </span>
  );
};

DotsLoader.propTypes = {
  isLoading: bool,
};

export default DotsLoader;

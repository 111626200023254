import React, { Component } from 'react';

import { ErrorBoundaryModal } from './components/ErrorBoundaryModal';
import { sendSentryBoundary } from '../../../services/sentry';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    sendSentryBoundary(error, errorInfo);
  }

  render() {
    return (
      <>
        {this.state.hasError ? (
          <ErrorBoundaryModal {...this.state} />
        ) : (
          this.props.children
        )}
      </>

    );
  }
}

import React, { Component, Fragment } from 'react';

import {hot} from 'react-hot-loader/root';

// Constants
import {
  START_ROTATING_NEWS_DATE_KEY,
  ROTATED_NEWS_BLOCKS_COUNT,
  LAST_SENDED_NEWS_INDEX,
  NEWS_EXPIRES_IN_DAYS,
  ROTATED_NEWS_COUNT,
  OBSERVED_NEWS_KEY,
  VIEWED_NEWS_KEY,
  SESSION_KEY,
  LOCAL_STORAGE_NEWS,
  LANGUAGES,
} from '../../../constants';

// Utils
import {
  getNewsItemsCount,
  getOtherRubricLength,
} from '../../../helpers/system';
import { concatExtractedData, extractContent } from './helper';

// Services
import parseUrlParams from '../../../services/parseUrlParams';
import spliceUri from '../../../services/spliceURI';
import { getAbcAttributes, getAbcNumber } from '../../../services/abcParams';
import {
  getSessionData,
  setSessionData,
} from '../../../services/sessionStorage';
import {
  getLocalStorage,
  setLocalStorage,
} from '../../../services/localStorage';
import { getDeviceType } from '../../../helpers/mobileDetect';
import { setLanguage } from '../../../helpers/language';

// Components
import App from '../../../App';
import Iframe from '../Iframe';
import Header from '../Header';
import WithLoading from '../HOC/WithLoading';
import Head from '../Head';
import { sendAnalytics, sendStatistics } from '../../../api';

const mediaQueryString = '(max-width: 767px)';

class AppRoot extends Component {
  constructor(props) {
    super(props);

    const abcAttributes = getAbcAttributes();
    const abcNumber = getAbcNumber();
    const originalUrlParams = parseUrlParams();
    const isActiveNavItem = false;
    const otherRubricLength = getOtherRubricLength(abcAttributes);

    this.intervalId = '';

    this.state = {
      template: 'basic',
      activeNavItem: null,
      isTimerDisabled: process.env.NODE_ENV === 'development',
      url: '',
      isMobile: window.matchMedia(mediaQueryString).matches,
      ...this.getInitialState(
        isActiveNavItem,
        'isRotateViewedNews' in abcAttributes,
        abcAttributes,
      ),
      ...abcAttributes,
      isActiveNavItem,
      abcNumber,
      originalUrlParams,
      otherRubricLength,
      referrer: window.document.referrer,
      deviceType: getDeviceType(),
    };
  }

  getSession = () => sessionStorage.getItem(SESSION_KEY);

  setSession = () => sessionStorage.setItem(SESSION_KEY, true);

  checkViewedNewsExpires = () => {
    const viewedNewsDate = localStorage.getItem(START_ROTATING_NEWS_DATE_KEY);
    if (!viewedNewsDate) return;

    const dateNow = Date.now();
    const diffInDays = Math.floor((dateNow - viewedNewsDate) / 86400000);

    if (diffInDays >= NEWS_EXPIRES_IN_DAYS) {
      localStorage.removeItem(VIEWED_NEWS_KEY);
      localStorage.removeItem(START_ROTATING_NEWS_DATE_KEY);
      sessionStorage.removeItem(VIEWED_NEWS_KEY);
    }
  };

  getViewedNewsData = (isNewSession, shouldRotateNews) => {
    if (!shouldRotateNews) return {};

    const viewedNewsIds = {};
    const viewedData =
      sessionStorage.getItem(VIEWED_NEWS_KEY) ||
      localStorage.getItem(VIEWED_NEWS_KEY);

    if (isNewSession) {
      sessionStorage.setItem(VIEWED_NEWS_KEY, viewedData);
    }

    if (viewedData) {
      try {
        const parsedData = JSON.parse(viewedData);
        if (Array.isArray(parsedData)) {
          parsedData.forEach(chunk => {
            Object.entries(chunk).forEach(([key, value]) => {
              if (viewedNewsIds[key]) {
                viewedNewsIds[key].push(...value);
              } else {
                viewedNewsIds[key] = value;
              }
            });
          });
        }
      } catch (error) {}
    } else {
      localStorage.setItem(START_ROTATING_NEWS_DATE_KEY, Date.now());
    }
    return viewedNewsIds;
  };

  getInitialState = (
    isActiveNavItem,
    isRotateViewedNews,
    abcAttributes,
    viewedNewsIdsFromIframe = [],
  ) => {
    this.checkViewedNewsExpires();
    const isNewSession = !this.getSession();
    const shouldRotateNews = !isActiveNavItem && isRotateViewedNews;
    if (isNewSession) {
      this.setSession();
    }

    const viewedNewsIds = this.getViewedNewsData(
      isNewSession,
      shouldRotateNews,
    );
    const {
      newsItemsCount,
      enableOrderParamForBlocks = [],
      enableLangParam,
      rubricPeriod = {},
      sortingOrder,
    } = abcAttributes;

    return {
      isFirstLoad: true,
      isFirstRequest: true,
      isRequesting: false,
      params: {
        actions: 'click',
        type: 'rotator',
        params: {
          rubric: {
            limit: 40,
            news_id_list: viewedNewsIds.rubric ? viewedNewsIds.rubric : [],
            category: true,
            ...(enableOrderParamForBlocks.includes('rubric') && {
              order: sortingOrder || 'ctr',
            }),
            ...rubricPeriod,
          },
          main: {
            limit: 40,
            news_id_list: viewedNewsIds.main ? viewedNewsIds.main : [],
            category: false,
            ...(enableOrderParamForBlocks.includes('main') && {
              order: sortingOrder || 'ctr',
            }),
          },
          last: {
            limit: 40,
            news_id_list: viewedNewsIds.last
              ? [...viewedNewsIdsFromIframe, ...viewedNewsIds.last]
              : [...viewedNewsIdsFromIframe],
            category: false,
            ...(enableOrderParamForBlocks.includes('last') && {
              order: sortingOrder || 'ctr',
            }),
          },
        },
      },
      loadedItems: {
        last: [],
        main: [],
        rubric: [],
        ids: { last: [], main: [], rubric: [] },
        isEmptyLoad: { last: false, main: false, rubric: false },
        ...getNewsItemsCount(newsItemsCount),
      },
      data: [],
      isNewSession,
      enableLangParam,
    };
  };

  componentDidMount() {
    const { originalUrlParams } = this.state;
    const params = this.chooseParamsForRequest(originalUrlParams);

    window.matchMedia(mediaQueryString).addListener(e => {
      this.setState({ isMobile: e.matches, ...getNewsItemsCount() });
    });

    this.setState(state => {
      return {
        ...state,
        urlParams: params,
        params: {
          ...state.params,
          ...params,
        },
        isFirstLoad: false,
        isFirstRequest: false,
      };
    });

    if (this.state.isMultipleSending) {
      this.initSendingObservedNews();
    }
  }

  setActiveNavItem = navItem => {
    const { activeNavItem, isRotateViewedNews } = this.state;
    const abcAttributes = getAbcAttributes();

    if (activeNavItem && navItem && navItem.id === activeNavItem.id) {
      return;
    }

    const initialState = this.getInitialState(
      navItem,
      isRotateViewedNews,
      abcAttributes,
      this.state.newsIdsFromIframe,
    );

    this.setState(
      state => ({
        ...state,
        ...initialState,
        params: {
          ...state.params,
          ...initialState.params,
        },
        activeNavItem: navItem,
        isTimerDisabled: true,
        isFirstRequest: false,
      }),
      () => this.load(true),
    );
  };

  chooseParamsForRequest = urlParams => {
    const { state } = this;
    const stateUrlParams = urlParams ? urlParams : state.urlParams;

    // prettier-ignore
    return {
      ...(stateUrlParams.click_news_id ? { news_id: stateUrlParams.click_news_id } : {}),
      ...(stateUrlParams.news_id ? { news_id: stateUrlParams.news_id } : {}),
      ...(stateUrlParams.site_id ? { site_id: stateUrlParams.site_id } : {}),
      ...(stateUrlParams.informer_id && { informer_id: stateUrlParams.informer_id }),
      ...(stateUrlParams.view_news_id && state.isFirstRequest && !stateUrlParams.is_counted ? { view_news_id: stateUrlParams.view_news_id } : {}),
      ...(stateUrlParams.informer_id && stateUrlParams.a_b_c && state.isFirstRequest && !stateUrlParams.is_counted ? { a_b_c: stateUrlParams.a_b_c } : {}),
      ...(stateUrlParams.sortingOrder || {}),
      ...(stateUrlParams.click_uuid && { click_uuid: stateUrlParams.click_uuid }),
      ...(stateUrlParams.click_signature && { click_signature: stateUrlParams.click_signature }),
    };
  };

  getUrl = newsItem => {
    const { params, data, originalUrlParams } = this.state;
    const { user } = params;
    const content = data[0];

    return spliceUri({
      originalUrlParams,
      newsItem,
      user,
      loadedItems: content,
    });
  };

  getUserWithIframe = (user, iframeMessage) => {
    this.setState(
      state => ({
        iframeMessage,
        ...(user && {
          params: {
            ...state.params,
            user,
          },
        }),
      }),
      this.load,
    );
  };

  // Retrieve viewed news ids from iframe and add it to request to prevent user from viewing it again
  getNewsIdsFromIframe = newsIds => {
    if (!this.state.shouldSendViewedNewsFromIframe) return;

    this.setState(prevState => ({
      ...prevState,
      newsIdsFromIframe: newsIds,
      params: {
        ...prevState.params,
        params: {
          ...prevState.params.params,
          last: {
            ...prevState.params.params.last,
            news_id_list: [
              ...prevState.params.params.last.news_id_list,
              ...newsIds,
            ],
          },
        },
      },
    }));
  };

  prepareLoadingParams = () => {
    const { isFirstLoad, loadedItems, params } = this.state;
    const { ids, isEmptyLoad } = loadedItems;
    const limit = isFirstLoad ? 40 : 700;

    const getCorrectLimit = key => (isEmptyLoad[key] ? 0 : limit);

    return {
      last: {
        limit: getCorrectLimit('last'),
        news_id_list: ids.last,
        category: params.params.last.category,
        ...(params.params.last.order && { order: params.params.last.order }),
      },
      main: {
        limit: getCorrectLimit('main'),
        news_id_list: ids.main,
        category: params.params.main.category,
        ...(params.params.main.order && { order: params.params.main.order }),
      },
      rubric: {
        limit: getCorrectLimit('rubric'),
        news_id_list: ids.rubric,
        category: params.params.rubric.category,
        ...(params.params.rubric.order && {
          order: params.params.rubric.order,
        }),
      },
    };
  };

  setBaseNewsFromRubric = base => {
    if (!base?.news_id) return;

    this.setState(state => {
      const newState = Object.assign({}, state);
      const { switchBaseNews, data } = newState;
      const {
        baseNewsFromRubricIndex: currentIndex,
        isBaseNewsFromRubric: isBaseChanged,
        originRubricLength,
        originBaseNews,
      } = switchBaseNews;
      const baseIndex = currentIndex + 1 + Number(currentIndex === 0);

      // Detecting origin base news
      const originBase = isBaseChanged
        ? originBaseNews
        : { ...data[0].content.base };

      // Correction for short rubric on desktop
      // const rubricLengthCorrection = Number(!isMobile && currentIndex < originRubricLength);

      return {
        ...newState,
        otherRubricLength: originRubricLength,
        data: [{ content: { ...newState.data[0].content, base } }],
        switchBaseNews: {
          ...switchBaseNews,
          isBaseNewsFromRubric: true,
          originBaseNews: originBase,
          currentBaseNews: base,
          baseNewsFromRubricIndex: baseIndex,
        },
      };
    });
  };

  load = shouldClearState => {
    const {
      enableLangParam,
      iframeMessage,
      activeNavItem,
      params,
      originalUrlParams,
      referrer,
      deviceType,
      abcNumber,
      urlParams,
    } = this.state;
    const paramsForRequest = {
      views_mix: true,
      referer: referrer,
      device_type: deviceType,
      a_b_c: abcNumber,
      click_signature: urlParams.click_signature,
      click_uuid: urlParams.click_uuid,
      ...params,
      ...(activeNavItem && { category_uuid: activeNavItem.id }),
      ...(enableLangParam && { lang_on: true }),
    };

    this.setState({ isRequesting: true, isFirstLoad: false });

    return this.fetch(paramsForRequest)
      .then(responseData => {
        const data = responseData || getLocalStorage(LOCAL_STORAGE_NEWS, {});
        const isEmptyData = Object.keys(data).length === 0;

        if (responseData.content.base.language_id) {
          const language =
            responseData.content.base.language_id === 1
              ? LANGUAGES[1]
              : LANGUAGES[0];
          setLanguage(language);
        }

        if (isEmptyData) {
          this.newsClickHandler({
            position: 1,
            block: 'base',
            news_id: params.news_id,
          });

          window.open(originalUrlParams.click_news_url, '_self');
        }

        const { base } = data.content;
        const parseParams = this.chooseParamsForRequest();

        // Saving
        this.saveRecievedData(data, shouldClearState);
        this.saveReceivedDataToLocalStorage(data);

        const loadingParams = this.prepareLoadingParams();

        this.setState(state => {
          return {
            url: base.url,
            params: {
              params: loadingParams,
              user: data.user,
              ...parseParams,
              actions: state.params.actions,
              type: state.params.type,
            },
            data: [...state.data, data],
            isRequesting: false,
            isFirstRequest: false,
            isFirstLoad: false,
          };
        });

        iframeMessage('post', 'user', data.user);
      })
      .catch(error => {
        this.setState({
          isRequesting: false,
          data: [],
        });
      });
  };

  fetch = ({ type, actions, ...otherTargetParams }) =>
    sendStatistics({actions, data: otherTargetParams})

  getIdsOfLoadedItems = loadedItems => loadedItems.map(item => item.news_id);

  saveRecievedData = (receivedData, shouldClearState) => {
    const { data, params, isNewSession, isActiveNavItem, isRotateViewedNews } =
      this.state;
    const preparedData = shouldClearState ? [] : data;
    const compiledData = [...preparedData, receivedData];
    const extractedContent = extractContent(compiledData, 'content');
    const correctParams = params.params;

    const newLoadedItems = {
      ids: {},
      isEmptyLoad: {},
    };
    const newsIdsToSave = {};

    Object.keys(correctParams).forEach(key => {
      if (!receivedData.content[key]) return;

      const dataLength = receivedData.content[key].length;
      const limit = correctParams[key].limit;
      const extractedData = extractedContent(key);
      const concatinatedData = concatExtractedData(extractedData);

      const isNotEmptyData = dataLength !== 0;
      const isEqualToLimit = dataLength === limit;
      const isNotEnoughToLoad = isNotEmptyData && isEqualToLimit;
      const idsOfLoadedItem = this.getIdsOfLoadedItems(concatinatedData);
      newLoadedItems[key] = concatinatedData;
      newLoadedItems.ids[key] = [
        ...correctParams[key].news_id_list,
        ...idsOfLoadedItem,
      ];
      newLoadedItems.isEmptyLoad[key] = !isNotEnoughToLoad;
      newsIdsToSave[key] = idsOfLoadedItem.slice(0, ROTATED_NEWS_COUNT);
    });

    if ((!data || !data.length) && !isActiveNavItem && isRotateViewedNews) {
      if (isNewSession) {
        const viewedData = localStorage.getItem(VIEWED_NEWS_KEY);
        if (viewedData) {
          try {
            const parsedData = JSON.parse(viewedData);
            if (parsedData && parsedData.length >= ROTATED_NEWS_BLOCKS_COUNT) {
              const newParsedData = parsedData.slice(1);
              newParsedData.push(newsIdsToSave);
              localStorage.setItem(
                VIEWED_NEWS_KEY,
                JSON.stringify(newParsedData),
              );
            } else {
              parsedData.push(newsIdsToSave);
              localStorage.setItem(VIEWED_NEWS_KEY, JSON.stringify(parsedData));
            }
          } catch (error) {}
        } else {
          localStorage.setItem(
            VIEWED_NEWS_KEY,
            JSON.stringify([newsIdsToSave]),
          );
        }
      }
    }

    this.setState(state => ({
      loadedItems: { ...state.loadedItems, ...newLoadedItems },
    }));
  };

  saveReceivedDataToLocalStorage = data => {
    setLocalStorage(LOCAL_STORAGE_NEWS, {
      ...data,
      content: {
        ...data.content,
        last: [...this.state.loadedItems.last, ...data.content.last],
        main: [...this.state.loadedItems.main, ...data.content.main],
        rubric: [...this.state.loadedItems.rubric, ...data.content.rubric],
      },
    });
  };

  sendAnalyticsRequest = requestParams => sendAnalytics({data: requestParams});

  observeNewsHandler = news => {
    const { params, originalUrlParams, abcNumber, referrer, deviceType } =
      this.state;
    const preparedNews = {
      section: 'rotator',
      action: 'view',
      a_b_c: abcNumber,
      user: params.user,
      informer_id: originalUrlParams.informer_id,
      referer: referrer,
      device_type: deviceType,
      ...news,
    };

    const observedNews = getSessionData({
      key: OBSERVED_NEWS_KEY,
      initData: [],
    });
    const isIncludedNews =
      observedNews && observedNews.find(el => el.news_id === news.news_id);
    const isShouldBeAdded = Array.isArray(observedNews) && !isIncludedNews;

    if (isShouldBeAdded) {
      // TODO: a_b_c=5 remove if multiple news sending will be approved
      // this.sendAnalyticsRequest(news);
      // observedNews.push(news.news_id);
      observedNews.push(preparedNews);
      sessionStorage.setItem(OBSERVED_NEWS_KEY, JSON.stringify(observedNews));
    }
  };

  initSendingObservedNews = () => {
    this.intervalId = setInterval(() => {
      const observedNews = getSessionData({
        key: OBSERVED_NEWS_KEY,
        initData: [],
      });
      const lastSendedNewsIndex = getSessionData({
        key: LAST_SENDED_NEWS_INDEX,
        initData: 0,
      });
      const isReadyToSend =
        observedNews && observedNews.length !== lastSendedNewsIndex;

      if (isReadyToSend) {
        this.sendAnalyticsRequest(observedNews.slice(lastSendedNewsIndex)).then(
          () =>
            setSessionData({
              key: LAST_SENDED_NEWS_INDEX,
              data: observedNews.length,
            }),
        );
      }
    }, 1000);
  };

  sendGATrackingData = data => {
    if (!data) return;

    if (window.gtag) {
      window.gtag('event', 'Click on news', {
        event_category: data.source,
      });
    }
  };

  newsClickHandler = (news, additionalData = {}) => {
    const { params, originalUrlParams, abcNumber, referrer, deviceType } =
      this.state;
    const { view_uuid, view_signature, ...cleanedNews } = news;
    const data = [
      {
        ...cleanedNews,
        action: 'click',
        section: 'rotator',
        user: params.user,
        informer_id: originalUrlParams.informer_id,
        a_b_c: abcNumber,
        referer: referrer,
        device_type: deviceType,
        click_signature: additionalData.click_signature,
        click_uuid: additionalData.click_uuid,
      },
    ];

    this.sendGATrackingData(additionalData);
    this.sendAnalyticsRequest(data);
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const {
      isMobileHeader,
      isObserveNews,
      activeNavItem,
      googleFont,
      abcNumber,
      isMobile,
      template,
      data,
    } = this.state;

    return (
      <Fragment>
        <Head
          googleFont={googleFont}
          data={data}
          themeColor={template === 'zen' ? '#fff' : '#000'}
        />
        <Header
          onNavItemClick={this.setActiveNavItem}
          isMobileHeader={isMobileHeader}
          activeNavItem={activeNavItem}
          abcNumber={abcNumber}
          isMobile={isMobile}
          template={template}
        />
        <WithLoading
          component={App}
          data={data}
          actions={{
            load: this.load,
            getUrl: this.getUrl,
            observeNewsHandler: this.observeNewsHandler,
            newsClickHandler: isObserveNews && this.newsClickHandler,
          }}
          isObserveNews={isObserveNews}
          setBaseNewsFromRubric={this.setBaseNewsFromRubric}
          {...this.state}
        />
        <Iframe
          getNewsIds={this.getNewsIdsFromIframe}
          getUser={this.getUserWithIframe}
        />
      </Fragment>
    );
  }
}

export default hot(AppRoot);

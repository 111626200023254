import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { decodeEntities } from '../../../services/decodeData';
import ThumborImage from '../ThumborImage';
import useIntersection from '../../../hooks/useIntersection';
import styles from './style.module.scss';

const MainNews = props => {
  const {
    view_signature,
    view_uuid,
    withoutReadMoreButton,
    imageHeight,
    imageWidth,
    onClick,
    source,
    img,
    url,
    id,
    position,
    block,
    onObserve,
  } = props;
  const title = decodeEntities(props.title);
  const newsTrackingData = {
    news_id: id,
    position,
    block,
    view_signature,
    view_uuid,
  };
  const { observerEntry, observerRef } = useIntersection();
  const { t } = useTranslation();

  useEffect(() => {
    if (observerEntry.isIntersecting && onObserve) {
      onObserve(newsTrackingData);
    }
  }, [observerEntry.isIntersecting, onObserve, newsTrackingData]);

  return (
    <a
      ref={observerRef}
      href={url}
      onMouseDown={() => onClick(newsTrackingData)}
      className={`${styles.wrapper} main-news`}
      rel="noopener"
      referrerPolicy="origin"
      target="_blank"
    >
      <div className={styles['image-wrapper']}>
        <ThumborImage
          className={styles.image}
          src={img}
          alt={title}
          width={imageWidth}
          height={imageHeight}
        />
      </div>
      <span className={styles.title}>{title}</span>
      {source && <span className={styles.sources}>{source}</span>}
      {!withoutReadMoreButton && (
        <span className={styles.button}>{t('readMore')}</span>
      )}
    </a>
  );
};

MainNews.propTypes = {
  withoutReadMoreButton: PropTypes.bool,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  onClick: PropTypes.func,
  source: PropTypes.string,
  img: PropTypes.string,
  url: PropTypes.string,
};

export default MainNews;

import React from 'react';
import Nav from '../../Nav';
import LanguageSwitcher from '../../LanguageSwitcher';

const MobileMenu = ({ className, onClose, ...otherProps }) => (
  <div className={className}>
    <div className="container">
      <Nav {...otherProps} />
      {/* TODO Temporarily decided to disable the language switcher*/}
      {/*<hr/>*/}
      {/*<LanguageSwitcher />*/}
    </div>
    <div className="header__hide-area" onClick={onClose} />
  </div>
);

export default MobileMenu;

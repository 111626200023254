import React from 'react';
import DetailNews from '../DetailNews';

const NewsBlock = props => {
  const {
    base,
    actions,
    mainClick,
    newsArray,
    isObserveNews,
    newsComponent = DetailNews,
    componentProps = {},
    positionStart = 0,
  } = props;
  const { getUrl, observeNewsHandler } = actions;
  // const { base } = data[0].content;

  return newsArray?.length ? (
    <>
      {newsArray.map((item, i) => {
        if (item.isHidden) return null;

        const NewsComponent = newsComponent;
        const newsProps = {
          ...item,
          title: item.title,
          img: item.img,
          url: getUrl(item),
          baseUrl: base.url,
          callback: (data) => mainClick(data, item),
          position: positionStart + i + 1,
          ...(isObserveNews ? { onObserve: observeNewsHandler } : {}),
          ...componentProps,
        };

        return (
          <div className="news-item-wrapper" key={item.news_id}>
            <NewsComponent {...newsProps} />
          </div>
        );
      })}
    </>
  ) : null;
};

NewsBlock.propTypes = {};

export default NewsBlock;

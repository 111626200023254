export const getEnvironmentTitle = host => {
  switch (true) {
    case host.includes('dev'):
      return 'dev';
    case host.includes('localhost'):
      return 'local';
    case host.includes('stage'):
      return 'stage';
    default:
      return 'prod';
  }
};

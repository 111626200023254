import React from 'react';
import EmptyData from '../../Errors/components/EmptyData';
import { Modal } from '../../Modal';

export const ErrorBoundaryModal = ({ error = {}, errorInfo }) => {

  return (
    <Modal
      isVisible={true}
      body={
        <>
          <EmptyData
            text={
              <div>
                <h3>Обнаружена ошибка!</h3>
                <div>
                  Пожалуйста, попробуйте зайти позже
                </div>
              </div>
            }
            isError
          />
        </>
      }
    />
  );
};

import { sendSentryFetch } from '../services/sentry';
import { convertToJson } from '../utils/json';

const apiUrl = url => `${process.env.REACT_APP_URL}${url}`;

const fetchApi = (...args) => fetch(...args)
  .then(response => response.json())
  .catch(sendSentryFetch);

const createRequest = ({ url, method, data }) => fetchApi(
  apiUrl(url),
  { method, body: convertToJson(data) },
);

export const sendRequest = {
  get: (url, data) => createRequest({ url, method: 'GET', data }),
  post: (url, data) => createRequest({ url, method: 'POST', data }),
};

import React from 'react';
import classnames from 'classnames';

import DotsLoader from '../../../Loader/DotsLoader';

import iconEmpty from '../../icons/empty.svg';
import iconDisconnect from '../../icons/connection.svg';
import iconClose from '../../icons/close.svg';

const EmptyData = ({ text = 'Нет данных', children, isDisconnect, isError }) => {
  const iconSrc = classnames({
    [iconDisconnect]: isDisconnect,
    [iconEmpty]: !isDisconnect && !isError,
    [iconClose]: isError,
  })

  const iconClassNames = classnames({
    'empty-data__icon': true,
    'empty-data__icon--marked': isDisconnect || isError,
  })

  return (
    <div className="empty-data">
      <div className={iconClassNames}>
        <img src={iconSrc} alt="Нет данных" width="70" height="70" />
      </div>
      <div style={{ textAlign: 'center' }}>
        {text}
        {isDisconnect && (
          <div>
            Перенаправление{<DotsLoader isRedirect isLoading />}
          </div>
        )}
        <div>{children}</div>
      </div>
    </div>
  );
}

export default EmptyData;

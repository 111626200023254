import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { useTranslation } from 'react-i18next';

const TitleCategory = ({ titleKey }) => {
  const { t } = useTranslation();
  return <h2 className="title-category">{t(titleKey)}</h2>;
};

TitleCategory.propTypes = {
  titleKey: PropTypes.string,
};

export default TitleCategory;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Nav from '../Nav';
import './style.scss';
import MobileMenu from './components/MobileMenu';
import WithComponent from '../HOC/WithComponent';
import StickyContainer from '../StickyContainer';
import LogoBasic from '../../templates/Basic/components/LogoBasic';
import LogoZen from '../../templates/Zen/components/LogoZen';
import LanguageSwitcher from "../LanguageSwitcher";

const rootClassName = 'header';

// Modify class names

const modifyHeader = ({isMobile, template, isMobileHeader, abcNumber}) =>
  classNames({
    [`${rootClassName}`]: true,
    [`${rootClassName}--mobile`]: isMobile,
    [`${rootClassName}--${template}`]: template,
    [`${rootClassName}--${abcNumber}`]: abcNumber,
    [`${rootClassName}--always-mobile`]: isMobileHeader,
  });
const modifyLogo = ({template, isMobile, isMobileHeader}) =>
  classNames({
    [`${rootClassName}__title`]: isMobileHeader || isMobile,
    [`${rootClassName}__logo`]: template === 'zen' && !isMobileHeader || !isMobile,
  })

const modifyMobileMenu = isOpen =>
  classNames({ [`${rootClassName}__mobile-menu--open`]: isOpen });

const modifyMenuButton = isOpen =>
  classNames({ [`${rootClassName}__menu-button--open`]: isOpen });

const Header = props => {
  const {
    disableStickyHandler,
    isMobileHeader,
    onNavItemClick,
    activeNavItem,
    isMobile,
    template,
  } = props;
  const [isMenuOpen, setMenuState] = useState(false);

  const toggleMenu = () => {
    setMenuState(prevMenuState => !prevMenuState);
    if (disableStickyHandler) {
      disableStickyHandler(isMenuOpen);
    }
  };

  const handleNavItemClick = (e, navItem) => {
    e.preventDefault();
    onNavItemClick(navItem);
    if (isMobile && isMenuOpen) {
      setMenuState(false);
      if (disableStickyHandler) {
        disableStickyHandler(true);
      }
    }
  };

  return (
    <WithComponent withComponent={isMobileHeader || isMobile} component={StickyContainer} >
      <header
        className={modifyHeader(props)}
        template={template}
      >
        <div className={`${rootClassName}__container container`}>
            <a
              href="/"
              className={modifyLogo(props)}
              onClick={handleNavItemClick}
            >
              {template === 'basic' && (
                <LogoBasic isMobileHeader={isMobileHeader || isMobile} rootClassName={rootClassName}/>
              )}
              {template === 'zen' && (
                <LogoZen />
              )}
            </a>

          {!(isMobileHeader || isMobile) && (
            <Nav
              onNavItemClick={handleNavItemClick}
              activeNavItem={activeNavItem}
            />
          )}
          <div className={`${rootClassName}__menu-button-wrapper `}>
            <div
              onClick={toggleMenu}
              className={`${rootClassName}__menu-button ${modifyMenuButton(
                isMenuOpen,
              )}`}
            >
              <div/>
            </div>
          </div>

          {/* TODO Temporarily decided to disable the language switcher*/}
          {/*{!isMobile && <LanguageSwitcher/>}*/}
        </div>

        {/* Mobile menu */}
        <MobileMenu
          className={`${rootClassName}__mobile-menu ${modifyMobileMenu(
            isMenuOpen,
          )}`}
          onNavItemClick={handleNavItemClick}
          activeNavItem={activeNavItem}
          onClose={toggleMenu}
          isMobile
        />
      </header>
    </WithComponent>
  );
};

Header.propTypes = {
  isMobile: PropTypes.bool,
  activeNavItem: PropTypes.shape({
    titleKey: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  onNavItemClick: PropTypes.func.isRequired,
};

export default Header;

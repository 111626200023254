import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { getEnvironmentTitle } from '../utils/environment';

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    tracesSampleRate: 1.0,
    environment: getEnvironmentTitle(window.location.host),
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
  });
};

export const sendSentry = (error, errorInfo) => {
  Sentry.withScope(scope => {
    if (errorInfo) scope.setExtras(errorInfo);

    scope.setTag('tag', errorInfo.tag);

    Sentry.captureException(error, scope);
  });
};

export const sendSentryBoundary = (error, errorInfo) => {
  sendSentry(error, {
    ...errorInfo,
    tag: 'Boundary Error',
  });
};

export const sendSentryFetch = error => {
  const { url = '', method = '' } = error?.config || {};

  class FetchError extends Error {
    constructor(message) {
      super(message);
      this.name = `Client request error (${error.response?.status || '---'})`;
    }
  }

  sendSentry(new FetchError(`${method.toUpperCase()}: ${url}`), {
    tag: 'Fetch Error',
  });
};

export const sendSentryCustom = ({ error, name }) => {
  if (!error) return;

  error.message = `${error.name}: ${error.message}`; // eslint-disable-line no-param-reassign
  error.name = name; // eslint-disable-line no-param-reassign

  Sentry.withScope(scope => {
    scope.setTag('tag', name);
    Sentry.captureException(error);
  });
};
